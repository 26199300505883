<template>
  <div class="w-screen flex h-12 items-center border-b-2">
    <div class="flex-grow md:w-5/6">
      <h1 class="text-left ml-3 md:ml-8 text-xl md:text-2xl animate__animated animate__backInDown">Lorenzo Andreasi</h1>
    </div>
    <div class="flex-grow flex flex-row-reverse animate__animated animate__backInRight">
      <button v-on:click="$emit('darkToggle')" class="mr-6">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-1/1" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="!isDark">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-1/1" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-else>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      </button>
      <div>
        <a href="https://lollo03.github.io/Yathzee-board/"><h3 style="margin: 2em">Yathzee</h3></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["darkToggle"],
  props: ["isDark"],
};
</script>

<style></style>
