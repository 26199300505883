<template>
  <div>
    <div class="flex">
      <div class="flex-none w-1/2 p-3">
        <img src="../assets/Profilo.jpg" style="border-radius: 50%" />
      </div>
      <div class="flex-grow p-1 flex flex-col">
        <div class="flex-grow flex items-center justify-center">
          <h1 class="text-xl transition duration-500 ease-in-out hover:text-purple-400 transform hover:-translate-y-1 hover:scale-110">Lorenzo Andreasi</h1>
        </div>
        <div class="flex-grow flex items-center justify-center transition duration-500 ease-in-out hover:text-purple-400 transform hover:-translate-y-1 hover:scale-110">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <p>Termoli (CB)</p>
        </div>
        <div class="flex-grow flex items-center justify-center transition duration-500 ease-in-out hover:text-purple-400 transform hover:-translate-y-1 hover:scale-110">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <p>02/05/2003</p>
        </div>
        <div class="flex-grow flex items-center justify-center transition duration-500 ease-in-out hover:text-purple-400 transform hover:-translate-y-1 hover:scale-110">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M12 14l9-5-9-5-9 5 9 5z" />
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            />
          </svg>
          <p>PoliMI</p>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <div class="flex p-5">
        <a href="https://www.instagram.com/lolloandr/?hl=it" class="flex-grow flex no-underline text-black dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 flex-grow transition duration-500 ease-in-out hover:text-purple-400 transform hover:-translate-y-1 hover:scale-110"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentcolor"
          >
            <path
              d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
            />
          </svg>
        </a>

        <a href="https://github.com/lollo03" class="flex-grow flex no-underline text-black dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 flex-grow transition duration-500 ease-in-out hover:text-purple-400 transform hover:-translate-y-1 hover:scale-110"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentcolor"
          >
            <path
              d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
            />
          </svg>
        </a>
      </div>
    </div>
    <hr />
    <div class="p-6 text-justify">
      <Markdown :source="aboutme" :plugins="plugins" :html="true" class="mb-6" />
    </div>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import MarkdownItTaskLists from "markdown-it-task-lists";
import { ottieniAbout } from "../api";

export default {
  name: "Scheda",
  components: { Markdown },
  async setup() {
    let aboutme = await ottieniAbout();

    return {
      aboutme,
      plugins: [
        {
          plugin: MarkdownItTaskLists,
        },
      ],
    };
  },
};
</script>

<style scoped>
img {
  border: 1px solid black;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}

.md {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

img:hover {
  border: 5px solid #b19ff4;
}
</style>
